@font-face {
  font-family: "icon-font";
  src: url('../fonts/icon-font/icon-font.eot');
  src: url('../fonts/icon-font/icon-font.eot?#iefix') format('eot'),
    url('../fonts/icon-font/icon-font.woff2') format('woff2'),
    url('../fonts/icon-font/icon-font.woff') format('woff'),
    url('../fonts/icon-font/icon-font.ttf') format('truetype'),
    url('../fonts/icon-font/icon-font.svg#icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin fi() {
	display: block;
	font-family: "icon-font";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fi {
  display: block;
}

.fi.fi:before {
  display: block;
  font-family: "icon-font";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi.fi-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fi.fi-2x { font-size: 2em; }
.fi.fi-3x { font-size: 3em; }
.fi.fi-4x { font-size: 4em; }
.fi.fi-5x { font-size: 5em; }
.fi.fi-fw {
  width: 1.2857142857142858em;
  text-align: center;
}


	$fi-angle-down: "\EA01";
	.fi.fi-angle-down:before { display: block; content: "\EA01" }

	$fi-angle-left: "\EA02";
	.fi.fi-angle-left:before { display: block; content: "\EA02" }

	$fi-angle-right: "\EA03";
	.fi.fi-angle-right:before { display: block; content: "\EA03" }

	$fi-angle-top: "\EA04";
	.fi.fi-angle-top:before { display: block; content: "\EA04" }

	$fi-close: "\EA05";
	.fi.fi-close:before { display: block; content: "\EA05" }

	$fi-home-email: "\EA06";
	.fi.fi-home-email:before { display: block; content: "\EA06" }

	$fi-home-phone: "\EA07";
	.fi.fi-home-phone:before { display: block; content: "\EA07" }