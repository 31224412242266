.page-sidebar {
	margin-bottom: 30px;

	.widget {
	}

	.widget-menu {
		padding: 0 30px;

		nav {
			> ul {
				> li {
					> a {
						font-size: 14px;
						font-weight: 300;
						color: #fff;
						display: block;
						text-transform: uppercase;
						padding: 25px 0;
						text-align: center;
						border-bottom: 1px solid fade-out(#fff, 0.8);
						position: relative;
					}

					> ul {
						background: fade-out(#fff, 0.9);
						margin: 0 -30px;
						padding: 0 0;
						display: none;

						> li {
							> a {
								font-size: 13px;
								font-weight: 300;
								color: #fff;
								display: block;
								padding: 15px 30px;
								text-align: center;
							}
						}

						> li + li {
							a {
								border-top: 1px solid fade-out(#fff, 0.8);
							}
						}
					}
				}

				> li.active {
					> a {
						font-weight: 700;

						&:before {
							@include fa-icon;
							content: "\f107";
							font-size: 17px;
							line-height: 7px;
							color: #fff;
							position: absolute;
							bottom: 5px;
							left: 50%;
							margin-left: -10px;
							width: 20px;
						}
					}

					> ul {
						display: block;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		display: none;
	}
}