.news-list {
	.item {
		margin-bottom: 40px;

		.text-box {
			@include textBox();
			min-height: 315px;
			position: relative;
			padding: 0;

			.item-btn {
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.item {
			.text-box {
				.item-date {
					position: relative;
					margin: 20px 0;
					padding: 10px 20px;
					display: block;
				}
			}
		}
	}
}

.services-list {
	.item {
		margin-bottom: 40px;

		.text-box {
			@include textBox();

			.item-title {
				height: 55px;
				display: table-cell;
				vertical-align: middle;
				width: 1%;
			}

			.btn-more {
				display: inline-block;
				width: 30px;
				height: 30px;
				border-radius: 30px;
				border: 2px solid darken($hr-border-color, 10%);
				text-align: center;
				color: darken($hr-border-color, 10%);
				margin-top: 10px;

				i {
					font-size: 20px;
					line-height: 26px;
				}
			}
		}
	}
}

.project-list {
	.item {
		margin-bottom: 40px;

		.image-box {
			position: relative;
			min-height: 280px;
			border: 1px solid $hr-border-color;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				@include transform(translate(-50%, -50%));
			}
		}

		.text-box {
			@include textBox();

			.item-title {
				height: 50px;
				display: table-cell;
				vertical-align: middle;
				width: 1%;
			}
		}
	}
}

.gallery-list {
	position: relative;

	.item {
		margin-bottom: 20px;
		position: relative;

		.zoom-box {
			position: absolute;
			top: 0;
			right: 0;
			padding: 15px;
			background: #fff;
			border: 1px solid $input-border-color;
			z-index: 1;

			i {
				display: block;
			}
		}

		.image-box {
			border: 1px solid $hr-border-color;

			img {
				width: 100% $i;
				height: 100% $i;
				object-position: center;
				object-fit: cover;
			}
		}

		.text-box {
			@include textBox();
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 20px;
			@include gradient-y(transparent, #000);

			.item-title {
				margin: 0 0 5px 0;
				color: #fff;
			}

			.item-text {
				color: #fff;
				margin: 0;
			}
		}
	}

	.slick-arrow {
		position: absolute;
		top: -80px;
		right: 10px;
		width: 35px;
		height: 35px;
		border-radius: 35px;
		border: 2px solid $gray-200;
		padding: 0;
		color: $gray-200;

		i {
			font-size: 22px;
			line-height: 31px;
		}

		&.slick-prev {
			margin-right: 40px;
		}

		&.slick-next {
		}
	}
}

.team-list {
	.item {
		margin-bottom: 40px;

		.text-box {
			@include textBox();

			.item-title {
				height: 65px;
				display: table-cell;
				vertical-align: middle;
				width: 1%;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.item {
			.image-box {
				text-align: center;
			}
		}
	}
}

.comment-list {
	.item {
		position: relative;
		margin-bottom: 40px;
		padding-bottom: 40px;
		border-bottom: 1px solid $hr-border-color;

		.image-box {
			position: absolute;
			width: 120px;
			border-radius: 120px;
			overflow: hidden;
			top: 0;
			left: 0;
			z-index: 1;
		}

		.text-box {
			@include textBox();
			padding: 0;
			padding-left: 120px + 30;
			min-height: 230px;
			position: relative;

			.item-title {
				padding: 0;
				margin-bottom: 20px;
			}

			.item-date {
				padding: 0;
				margin: 0;
				border: none;
				margin-bottom: 30px;

				i {
					float: left;
					margin-left: 0;
					margin-right: 15px;
				}
			}

			.item-btn {
				position: absolute;
				bottom: 0;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.item {
			.image-box {
				position: relative;
				width: 100%;
				text-align: center;
				margin-bottom: 20px;
			}

			.text-box {
				padding: 0;

				.item-title {
					padding: 0;
				}

				.item-date {
					position: relative;
					display: block;
					margin-bottom: 20px;
				}

				.item-btn {
					position: relative;
				}
			}
		}
	}
}

.product-list {
	.item {
		margin-bottom: 40px;
		position: relative;

		.goto-page {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		.price-box {
			border: 1px solid $hr-border-color;
			background: #fff;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			padding: 10px 20px;

			span {
				font-size: 20px;
				font-weight: 600;
				color: $body-color;
				letter-spacing: -1px;
			}
		}

		.image-box {
			border: 1px solid $hr-border-color;

			img {
				width: 100%;
			}
		}

		.text-box {
			@include textBox();

			.item-title {
				height: 65px;
				display: table-cell;
				vertical-align: middle;
				width: 1%;
			}
		}
	}
}

.category-list {
	.item {
		margin-bottom: 40px;
		position: relative;

		a.goto-page {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.price-box {
			border: 1px solid $hr-border-color;
			background: #fff;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			padding: 10px 20px;

			span {
				font-size: 20px;
				font-weight: 600;
				color: $body-color;
				letter-spacing: -1px;
			}
		}

		.image-box {
			border: 1px solid $hr-border-color;

			img {
				width: 100%;
			}
		}

		.text-box {
			@include textBox();

			.item-title {
				height: 70px;
				display: table-cell;
				vertical-align: middle;
				width: 1%;
			}
		}
	}
}

.document-list {
	.item {
		@extend .clearfix;
		position: relative;
		border-bottom: 1px solid $hr-border-color;
		padding: 15px 55px 15px 0;

		.icon-box {
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -25px;

			i {
				display: block;
			}
		}

		.text-box {
			float: left;
			line-height: 25px;

			.item-title {
				font-size: 16px;
				font-weight: 700;
				color: $body-color;
			}

			.item-btn {
				font-size: 14px;
				font-weight: 400;
			}
		}

		.info-box {
			font-size: 14px;
			font-weight: 300;
			color: $gray-300;
			float: right;

			.file-type {
			}

			.file-size {
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.item {
			.text-box {
				float: none;
			}

			.info-box {
				float: none;
			}
		}
	}
}

.certificate-list {
	.item {
		margin-bottom: 40px;

		.image-box {
			border: 1px solid $hr-border-color;
			padding: 9px;
		}

		.text-box {
			@include textBox();
			text-align: left;
			padding: 0;

			.item-title {
				height: 65px;
				display: table-cell;
				vertical-align: middle;
				width: 1%;
			}

			.item-btn {
				margin-top: 0;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.item {
			.image-box {
				text-align: center;
			}
		}
	}
}

.search-list {
	.item {
		border-bottom: 1px solid $hr-border-color;
		padding-bottom: 20px;
		margin-bottom: 20px;

		.item-title {
			font-size: 17px;
			font-weight: 700;
			color: $body-color;
			margin-bottom: 5px;
		}

		.item-url {
			margin-bottom: 10px;

			a {
				border-bottom: 1px solid;
			}
		}

		.item-description {
			font-size: 14px;
			font-weight: 400;
			color: $body-color;
			margin-bottom: 20px;
		}
	}
}

.faq-list {
	.item {
		padding: 0 20px;
		border: 1px solid $hr-border-color;
		border-radius: 2px;
		margin-bottom: 10px;

		.item-title {
			font-size: 15px;
			font-weight: 700;
			color: #000;
			padding: 20px 0;
			text-transform: uppercase;
			cursor: pointer;
		}

		.item-body {
			display: none;

			article {
				border-top: 1px solid $hr-border-color;
				padding-top: 20px;
				@include article();
			}
		}
	}
}

.video-gallery-list {
	.item {
		position: relative;
		border: 1px solid $hr-border-color;
		margin-bottom: 20px;

		> a.blank-page {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		.image-box {
			position: relative;

			.hover {
				.hover-bg {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0.5;
					background: #000;
				}

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					font-size: 44px;
					line-height: 50px;
					width: 50px;
					text-align: center;
					margin-top: -25px;
					margin-left: -25px;
				}
			}
		}

		.text-box {
			padding: 0 20px;

			.item-title {
				font-size: 18px;
				font-weight: 500;
				color: #000;
				letter-spacing: -1px;
				display: table-cell;
				vertical-align: middle;
				height: 80px;
				width: 1%;
			}
		}
	}
}

.reference-list {
	.item {
		.image-box {
			padding: 19px;
		}
	}
}