.page-tab {
	&__nav {
		border: none;
		margin-bottom: 40px;
	}

	&__item {
	}

	&__link {
		display: block;
		font-size: 18px;
		font-family: $fm;
		padding: 8px 35px;
		color: rgba($dark-blue-grey, 0.2);
		border: 1px solid transparent;
		border-radius: 40px;
		transition: all ease 0.3s;

		&.active {
			background: $light-blue-grey;
			border-color: $tiffany-blue;
			color: $dark-blue-grey;
		}
	}


	&__content {
	}

	&__pane {
	}

	@include media-breakpoint-down(sm) {
		&__nav {
			flex-wrap: nowrap;
			overflow-y: scroll;
			justify-content: center;
		}

		&__link {
			font-size: 17px;
			padding: 8px 20px;
		}
	}
}
