body {
	overflow-x: hidden;

	@include media-breakpoint-down(md) {
		padding-top: $header-height-sm;
	}
}

.container , .container-fluid {
	@include media-breakpoint-down(md) {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.home-content {
}

.site-content {
	@include media-breakpoint-down(md) {
	}
}
