.page-block {
	margin-bottom: 40px;

	.block-title {
		position: relative;
		padding: 5px 0 25px 0;
		margin: 0 0 25px 0;
		border-bottom: 1px solid $hr-border-color;

		h2 {
			font-size: 22px;
			font-weight: 700;
			color: $body-color;
			margin: 0;

			small {
				display: block;
				margin-top: 5px;
			}
		}

		.publish-date {
			position: absolute;
			top: 0;
			right: 0;
			border: 1px solid $hr-border-color;
			padding: 15px 25px;

			&:before {
				content: '';
				width: 30px;
				top: 1px;
				left: -31px;
				height: 100%;
				position: absolute;
				background: #fff;
			}

			i {
				display: block;
				float: left;
				margin: 0 10px 0 0;
				color: $gray-200;
				font-size: 19px;
			}

			span {
				font-size: 16px;
				font-weight: 400;
				color: $body-color;
				letter-spacing: -1px;
			}
		}

		.sub-menu {
			position: absolute;
			top: -18px;
			right: 0;

			ul {
				@extend .clearfix;
				margin: 0 -10px;

				li {
					float: left;
					padding: 0 10px;

					a {
						font-size: 14px;
						font-weight: 600;
						color: $gray-300;
						display: block;
						border-bottom-width: 4px;
						border-bottom-style: solid;
						border-bottom-color: transparent;
						padding: 25px 10px;
						@include transition(all ease 0.3s);
					}
				}

				li:hover {
					a {
						color: $body-color;
					}
				}

				li.active {
					a {
						color: $body-color;
					}
				}
			}
		}
	}

	.block-body {
		article {
			@include article();
		}

		.share-page {
			border-top: 1px solid $hr-border-color;
			padding-top: 25px;
			margin-top: 25px;

			.title {
				font-size: 13px;
				font-weight: 700;
				color: $body-color;
				text-transform: uppercase;
				margin-bottom: 15px;
				padding: 0 5px;
			}

			ul {
				@extend .clearfix;
				margin: 0 -6px;

				li {
					float: left;
					padding: 0 6px;

					a {
						display: inline-block;
						min-width: 140px;
						height: 40px;
						font-size: 14px;
						font-weight: 400;
						letter-spacing: -1px;
						background: #eee;
						color: #fff;
						border-radius: 6px;
						padding: 10px 20px;

						i {
							display: block;
							float: left;
							width: 15px;
							text-align: center;
							margin-right: 10px;
							font-size: 20px;
						}
					}
				}

				li.facebook {
					a {
						background: #0084ff;
					}
				}

				li.twitter {
					a {
						background: #55acee;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.block-title {
			.publish-date {
				position: relative;
				margin: 20px 0 -26px 0;
			}
		}
	}
}