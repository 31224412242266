.project-detail {
	.project-logo {
		position: relative;
		float: right;
		margin: 0 0 0 30px;
		width: 280px;
		height: 280px;
		border: 1px solid $hr-border-color;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translate(-50%, -50%));
		}
	}

	@include media-breakpoint-down(sm) {
		.project-logo {
			float: none;
			width: 100%;
			margin: 0 0 20px 0;

			img {
				width: auto $i;
			}
		}
	}
}