.product-properties {
	ul {
		@extend .clearfix;
		margin: 0 -1%;

		li {
			float: left;
			width: 48%;
			padding: 15px 0;
			margin: 0 1%;
			font: 14px/25px $ff;
			font-weight: 400;
			color: $body-color;
			border-bottom: 1px solid $hr-border-color;

			b, strong {
				text-align: left;
				display: block;
				font-weight: 700;
				color: $body-color;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		ul {
			margin: 0;

			li {
				width: 100%;
				margin: 0;
			}
		}
	}
}