.page-header {
	padding: 30px 0;
	margin: 0;
	border: none;
	background: $gray-100;

	h1 {
		font-size: 26px;
		font-weight: 500;
		color: $body-color;
		margin: 0 0 10px 0;
		padding: 0;
		text-transform: uppercase;
	}

	.btn-back-page {
		float: right;
		padding: 12px 0;

		a {
			font-size: 12px;
			font-weight: 300;
			display: inline-block;
			padding: 10px;
			text-transform: uppercase;
			color: $body-color;

			i {
				font-size: 12px;
				display: block;
				float: left;
				margin-right: 15px;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		h1 {
			text-align: center;
		}
		.btn-back-page {
			float: none;
			margin-top: 20px;
			border-top: 1px solid fade-out(#000, 0.9);
			text-align: center;
		}
	}
}