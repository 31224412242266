.contact-detail {
	.maps-box {
		#maps {
			width: 100%;
			height: 440px;
			background: $hr-border-color;
		}
	}

	.contact-box {
		h3 {
			margin: 0 0 20px 0;
			padding: 0;
			font-size: 18px;
			font-weight: 500;
			color: $body-color;
			letter-spacing: -1px;;
		}

		p {
			font-size: 15px;
			font-weight: 300;
			margin-bottom: 25px;
			color: $body-color;

			b, strong {
				color: $body-color;
				font-weight: 700;
				font-size: 12px;
				display: inline-block;
				text-transform: uppercase;
			}

			a {
				color: $body-color;
				text-decoration: underline;
			}
		}
	}

	.social-box {
		ul {
			@extend .clearfix;

			li {
				margin-bottom: 5px;

				a {
					display: block;
					min-width: 140px;
					height: 40px;
					font-size: 14px;
					font-weight: 300;
					letter-spacing: -1px;
					background: #eee;
					color: #fff;
					border-radius: 6px;
					padding: 10px 20px;
					text-align: left;
					text-transform: capitalize;

					i {
						display: block;
						float: left;
						width: 25px;
						margin-right: 10px;
						font-size: 20px;
					}
				}
			}

			li.facebook {
				a {
					background: #0084ff;
				}

				a:hover {
					background: darken(#0084ff, 5%);
				}
			}

			li.twitter {
				a {
					background: #55acee;
				}

				a:hover {
					background: darken(#55acee, 5%);
				}
			}

			li.google-plus {
				a {
					background: #dd4b39;
				}

				a:hover {
					background: darken(#dd4b39, 5%);
				}
			}

			li.instagram {
				a {
					background: #e4405f;
				}

				a:hover {
					background: darken(#e4405f, 5%);
				}
			}

			li.youtube {
				a {
					background: #cd201f;
				}

				a:hover {
					background: darken(#cd201f, 5%);
				}
			}

			li.linkedin {
				a {
					background: #0077B5;
				}

				a:hover {
					background: darken(#0077B5, 5%);
				}
			}

			li.pinterest {
				a {
					background: #bd081c;
				}

				a:hover {
					background: darken(#bd081c, 5%);
				}
			}

			li.vimeo {
				a {
					background: #1ab7ea;
				}

				a:hover {
					background: darken(#1ab7ea, 5%);
				}
			}

			li.foursquare {
				a {
					background: #f94877;
				}

				a:hover {
					background: darken(#f94877, 5%);
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.block-body {
			margin: 0 0 30px 0;
		}
	}

	@include media-breakpoint-down(md) {
		.social-box {
			ul {
				margin: 0 -5px;

				li {
					float: left;
					padding: 0 5px;
				}
			}
		}
	}
}