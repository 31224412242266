.box-group {

	.box-group-inner {
		margin: 40px 0;
	}

	.box-group-head {
		position: relative;
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid $hr-border-color;

		h2 {
			font-size: 30px;
			font-weight: 700;
			margin: 0;
			padding: 0;

			.small {
				opacity: 0.5;
				display: block;
				font-size: 50%;
			}
		}

		.bg-color-1 {
			width: 80px;
			height: 3px;
			position: absolute;
			bottom: -1px;
			left: 0;
		}

		&.text-center {
			.bg-color-1 {
				left: 50%;
				margin-left: -(80px / 2);
			}
		}
	}

	.box-group-body {
		.item-title {
			font-size: 16px;
			font-weight: 700;
			color: $body-color;
			letter-spacing: -1px;

			span {
				display: block;
				font-weight: 400;
				font-size: 14px;
				margin-top: 5px;
			}
		}

		.item-text {
			font-size: 14px;
			font-weight: 400;
			color: $body-color;
			margin-top: 10px;
		}

		.item-social {
			display: table;
			margin: 0 auto;

			ul {
				@extend .clearfix;
				margin: 0 -2px;

				li {
					float: left;
					padding: 0 2px;

					a {
						display: block;
						width: 30px;
						height: 30px;
						border-radius: 30px;
						background: $input-border-color;
						color: #fff;
						text-align: center;

						i {
							font-size: 14px;
							line-height: 30px;
						}
					}
				}
			}
		}

		.item-date {
			font-size: 16px;
			font-weight: 300;
			color: $body-color;
			letter-spacing: -1px;
			border: 1px solid $hr-border-color;
			padding: 15px 20px;

			i {
				display: block;
				float: right;
				margin: 0 0 0 15px;
				font-size: 19px;
				color: $gray-300;
			}
		}

		.item-price {
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			border: 1px solid $hr-border-color;
			background: #fff;
			padding: 15px 20px;

			span {
				font-size: 20px;
				font-weight: 700;
				letter-spacing: -1px;
				display: block;
			}
		}

		.item-btn {
			min-width: 140px;
			height: 40px;
			line-height: 40px;
			padding: 0;
			position: absolute;
			bottom: 0;
		}

		.item-arrow-btn {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			height: 100%;

			a {
				display: block;
				text-align: center;
				color: #000;
				line-height: 74px;
				height: 100%;

				i {
					font-size: 24px;
					line-height: 12px;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.center-image-canvas {
				img {
					max-width: 100%;
				}
			}
		}
	}
}