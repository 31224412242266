.page-breadcrumb {
	ul {
		@extend .clearfix;

		li {
			float: left;
			position: relative;
			padding-right: 35px;

			a {
				font-size: 13px;
				font-weight: 400;
				color: $body-color;
				text-transform: uppercase;
			}
		}

		li:before {
			@include fa-icon;
			font-family: $fa-font-name;
			font-weight: 900;
			content: fa-content($fa-var-chevron-right);
			font-size: 13px;
			color: $body-color;
			position: absolute;
			top: 5px;
			right: 13px;
			opacity: 0.8;
		}

		li:last-child {
			padding-right: 0;

			&:before {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		display: table;
		margin: 0 auto;
	}
}