


/* Z-INDEX */
.formError { z-index: 990; }
.formError .formErrorContent { z-index: 991; letter-spacing: 0; }
.formError .formErrorArrow { z-index: 996; }

.ui-dialog .formError { z-index: 5000; }
.ui-dialog .formError .formErrorContent { z-index: 5001; }
.ui-dialog .formError .formErrorArrow { z-index: 5006; }




.inputContainer {
	position: relative;
	float: left;
}

.formError {
	position: absolute;
	top: 0;
	left: inherit !important;
	right: 15px;
	display: block;
	cursor: pointer;
	text-align: left;
}

.formError.inline {
	position: relative;
	top: 0;
	left: 0;
	display: inline-block;
}

.ajaxSubmit {
	padding: 20px;
	background: #55ea55;
	border: 1px solid #999;
	display: none;
}

.formError .formErrorContent {
	width: 100%;
	background: #e2001a;
	position:relative;
	color: #fff;
	min-width: 120px;
	font-size: 11px;
	border: 1px solid #e2001a;
	padding: 4px 10px 4px 10px;
	border-radius: 3px;
	font-family: arial;
}

.formError.inline .formErrorContent {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
	border: none;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-o-border-radius: 0;
}

.greenPopup .formErrorContent {
	background: #33be40;
}

.blackPopup .formErrorContent {
	background: #393939;
	color: #FFF;
}

.formError .formErrorArrow {
	width: 15px;
	margin: -2px 0 0 13px;
	position:relative;
}
body[dir='rtl'] .formError .formErrorArrow,
body.rtl .formError .formErrorArrow {
	margin: -2px 13px 0 0;
}

.formError .formErrorArrowBottom {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
	margin: 0px 0 0 12px;
	top:2px;
}

.formError .formErrorArrow div {

	font-size: 0px;
	height: 1px;


	background: #e2001a;
	margin: 0 auto;
	line-height: 0;
	font-size: 0;
	display: block;
}

.formError .formErrorArrowBottom div {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
}

.greenPopup .formErrorArrow div {
	background: #33be40;
}

.blackPopup .formErrorArrow div {
	background: #393939;
	color: #FFF;
}

.formError .formErrorArrow .line10 {
	width: 15px;
	border: none;
}

.formError .formErrorArrow .line9 {
	width: 13px;
	border: none;
}

.formError .formErrorArrow .line8 {
	width: 11px;
}

.formError .formErrorArrow .line7 {
	width: 9px;
}

.formError .formErrorArrow .line6 {
	width: 7px;
}

.formError .formErrorArrow .line5 {
	width: 5px;
}

.formError .formErrorArrow .line4 {
	width: 3px;
}

.formError .formErrorArrow .line3 {
	width: 1px;
}

.formError .formErrorArrow .line2 {
	width: 3px;
	background: none;
}

.formError .formErrorArrow .line1 {
	width: 1px;
	background: none;
}

.formError {
	.formErrorContent { background: $red; border: 1px solid darken($red, 5%); color: #fff; }
	.formErrorArrow {
		div { background: $red; }
		.line1 , .line2 { display: none; }
	}
}