.product-detail {
	.product-gallery {
		height: 420px;

		.item {
			border: 1px solid $hr-border-color;
		}

		ul.slick-dots {
			display: table $i;
			margin: 20px auto 0;

			li {
				float: left;
				padding: 0 2px;

				button {
					padding: 0;
					width: 13px;
					height: 13px;
					background: darken($hr-border-color, 10%);
					border-radius: 13px;

					span {
						display: none;
					}
				}

				&.slick-active {
				}
			}
		}
	}

	.product-info {
		height: 420px;
		position: relative;

		.product-description {
			.block-title {
				.publish-date {
					span {
						font-size: 20px;
						font-weight: 700;
					}
				}
			}

			.btn-modal {
				min-width: 160px;
			}
		}

		.product-thumb-gallery {
			position: absolute;
			bottom: 0;
			width: 100%;

			.block-title {
				border: none;
				padding: 0;
				margin-bottom: 10px;
			}

			.block-body {
				padding-right: 40px;
			}

			.list {
				margin: 0 -4px;
				position: relative;

				.item {
					padding: 0 4px;
					cursor: pointer;

					.center-image-canvas {
						border: 1px solid $input-border-color;
					}
				}
			}

			.slick-arrow {
				position: absolute;
				top: 0;
				right: -40px;
				width: 30px;
				height: 100%;
				color: #fff;
				padding: 0;
				background: $gray-300;

				i {
					font-size: 22px;
					line-height: 31px;
				}

				&.slick-prev {
					display: none;
				}

				&.slick-next {
				}
			}
		}
	}

	.block-urun-ozellik, .block-file-list, .block-urun-tablo {
		img {
			max-width: 100%;
		}
	}

	@include media-breakpoint-down(sm) {
		.product-gallery {
			height: auto;
			margin-bottom: 30px;

			.item {
				img {
					width: 100%;
				}
			}
		}
		.product-info {
			height: auto;

			.product-description {
				text-align: center;

				.block-title {
					.publish-date {
						position: relative;
						margin: 20px 0 -26px 0;
					}
				}
			}

			.product-thumb-gallery {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.product-gallery {
			margin-bottom: 30px;

			.item {
				img {
					width: 100%;
				}
			}
		}
		.product-info {
			height: auto;

			.product-thumb-gallery {
				display: none;
			}
		}
	}
}

