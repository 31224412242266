.home-carousel {
	position: relative;

	&.slick-arrow-square {
		.slick-arrow {
			position: absolute;
			top: -86px;
			right: 0;
			width: 40px;
			height: 50px;
			border: 1px solid $hr-border-color;
			@include transition(all ease 0.3s);
			color: #000;
			padding: 0;

			i {
				font-size: 21px;
				line-height: 24px;
			}

			&.slick-prev {
				margin-right: 50px;
			}

			&.slick-next {
			}

			&:hover {
				background: darken($hr-border-color, 10%);
				color: #fff;
			}
		}
	}

	&.slick-arrow-circle {
		.slick-arrow {
			position: absolute;
			top: -70px;
			right: 0;
			width: 28px;
			height: 28px;
			border-radius: 28px;
			border: 2px solid darken($hr-border-color, 10%);
			color: darken($hr-border-color, 10%);
			text-align: center;
			padding: 0;
			@include transition(all ease 0.3s);

			i {
				font-size: 21px;
				line-height: 24px;
			}

			&.slick-prev {
				margin-right: 33px;
			}

			&.slick-next {
			}

			&:hover {
				background: darken($hr-border-color, 10%);
				color: #fff;
			}
		}
	}

	ul.slick-dots {
		position: absolute;
		top: -80px;
		right: 15px;
		margin: 0 -2px;

		li {
			float: left;
			padding: 0 2px;

			button {
				padding: 0;
				width: 13px;
				height: 13px;
				background: darken($hr-border-color, 10%);
				border-radius: 13px;

				span {
					display: none;
				}
			}

			&.slick-active {
			}
		}
	}
}

.home-referanslar-list {
	&.referanslar-list-1 {
		.item {
			.image-box {
				border: 1px solid $hr-border-color;
				height: 280px;
				position: relative;

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform(translate(-50%, -50%));
					max-width: 200px;
					width: 100%;
				}
			}
		}
	}

	&.referanslar-list-2 {
		.item {
			.image-box {
				width: 180px;
				height: 180px;
				position: relative;
				border: 1px solid $hr-border-color;

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform(translate(-50%, -50%));
					width: 100%;
					max-width: 150px;
				}
			}

			.text-box {
				position: relative;
				height: 140px;
				margin: 20px 0;
			}
		}
	}

	&.referanslar-list-3 {
		.item {
			.image-box {
				border: 1px solid $hr-border-color;
			}

			.text-box {
				height: 40px;
				display: table-cell;
				vertical-align: middle;
				text-align: center;
				width: 1%;
			}
		}

		@include media-breakpoint-down(sm) {
			.item {
				.image-box {
					img {
						margin: 0 auto;
						max-height: 200px;
					}
				}
			}
		}
	}
}

.home-haberler-list {
	&.haberler-list-1 {
		.item {
			.image-box {
				position: relative;

				.item-btn {
					position: absolute;
					bottom: 10px;
					left: 10px;
				}
			}

			.text-box {
				position: relative;
				height: 280px;
				padding: 30px 20px;
				margin-left: -22px;
				border: 1px solid $hr-border-color;
				border-left: 0;

				.item-date {
					position: absolute;
					bottom: -1px;
					right: -1px;
					padding: 15px 20px;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.item {
				.text-box {
					height: auto;
					padding: 20px;
					margin: 0;
					border-left: 1px solid $hr-border-color;

					.item-date {
						position: relative;
						margin: 20px -20px -20px -22px;
					}
				}
			}
		}
	}

	&.haberler-list-2 {
		.item {
			position: relative;

			.image-box {
			}

			.text-box {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: 20px;

				.text-box-inner {
					position: relative;
					z-index: 1;
					height: 100%;

					a {
						display: block;
						width: 100%;
						height: 100%;
					}
				}

				.item-date {
					color: #fff;
					border: none;
					padding: 0;
					position: absolute;
					top: 0;
					right: 0;
				}

				.item-title {
					color: #fff;
					position: absolute;
					bottom: -20px;
					left: 0;
					border-top: 1px solid #fff;
					padding: 20px 0;
					width: 100%;
					height: auto;
				}

				&:after {
					content: '';
					position: absolute;
					z-index: 0;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 80px;
					@include gradient-y(transparent, #000);
				}
			}
		}
	}

	&.haberler-list-3 {
		.item {
			.image-box {
				border: 1px solid $hr-border-color;
			}

			.text-box {
				height: 150px;
				position: relative;
				padding: 10px 0;

				.item-title {
					padding: 0 180px 0 0;
				}

				.item-date {
					position: absolute;
					top: 0;
					right: 0;
					padding: 10px 20px;
				}
			}
		}
	}
}

.home-urunler-list {
	&.urunler-list-1 {
		.item {
			position: relative;

			.image-box {
				border: 1px solid $hr-border-color;
				border-bottom: 0;
				margin-bottom: -1px;
			}

			.text-box {
				position: relative;
				padding: 0 20px;
				border: 1px solid $hr-border-color;
				height: 70px;
				display: table-cell;
				vertical-align: middle;
				width: 1%;
				padding-right: 40px;
			}
		}
	}

	&.urunler-list-2 {
		.item {
			border: 1px solid $hr-border-color;

			.text-box {
				position: relative;
				height: 280px;
				padding: 30px 20px 30px 0;

				.item-btn {
					position: absolute;
					bottom: 20px;
					left: 0px;
				}
			}

			.item-price {
				top: inherit;
				bottom: 20px;
				right: 20px;
				border: none;
				padding: 8px 0;
			}
		}
	}
}

.home-ekip-list {
	&.ekip-list-1 {
		.item {
			position: relative;

			.image-box {
				border: 1px solid $hr-border-color;
				margin-bottom: -1px;
				padding: 30px 0;
			}

			.text-box {
				position: relative;
				padding: 0 20px;
				border: 1px solid $hr-border-color;
				height: 70px;
				display: table-cell;
				vertical-align: middle;
				width: 1%;
			}
		}
	}
}

.home-kategori-list {
	&.kategori-list-1 {
		.item {
			position: relative;

			.image-box {
				margin-bottom: -1px;
			}

			.text-box {
				position: relative;
				padding: 0 20px;
				border: 1px solid $hr-border-color;
				height: 70px;
				display: table-cell;
				vertical-align: middle;
				width: 1%;

			}
		}
	}

	&.kategori-list-2 {
		.item {
			position: relative;

			.image-box {
				opacity: 0.5;
			}

			.text-box {
				position: absolute;
				bottom: 0;
				left: 0;
				padding: 20px;

				.item-title, .item-text {
					color: #fff;
				}
			}

			.btn-more {
				width: 40px;
				height: 50px;
				position: absolute;
				top: 20px;
				right: 20px;
				border: 1px solid #fff;
				color: #fff;
				text-align: center;

				i {
					line-height: 48px;
					font-size: 20px;
					@include transition(all ease 0.3s);
				}

				&:hover {
					i {
						@include transform(rotate(360deg));
					}
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.item {
				.image-box {
					img {
						width: 100%;
					}
				}
			}
		}
	}
}

.home-projeler-list {
	&.projeler-list-1 {
		.item {
			border: 1px solid $hr-border-color;
			position: relative;

			.image-box {
				height: 280px - 2;
				position: relative;
				overflow: hidden;

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform(translate(-50%, -50%));
					height: 100%;
				}

			}

			.item-date {
				position: absolute;
				top: -1px;
				right: -1px;
				background: #fff;
			}
		}
	}

	&.projeler-list-2 {
		.item {
			position: relative;

			.image-box {
				border: 1px solid $hr-border-color;
				border-bottom: 0;
				margin-bottom: -1px;
			}

			.text-box {
				position: relative;
				padding: 0 20px;
				border: 1px solid $hr-border-color;
				height: 70px;
				display: table-cell;
				vertical-align: middle;
				width: 1%;
				padding-right: 40px;
			}
		}
	}
}

.home-bulten-list {
	&.bulten-list-1 {
		border: 1px solid $hr-border-color;
		padding: 0 30px;

		.bulletin-title {
			font-size: 16px;
			font-weight: 500;
			color: #000;
			border-bottom: 1px solid $hr-border-color;
			padding: 30px 0;
		}

		.bulletin-body {
			height: 199px;
			display: table-cell;
			vertical-align: middle;
			width: 1%;

			p {
				font-size: 14px;
				font-weight: 400;
				color: #000;
				margin: 0 0 30px 0;
				max-width: 340px;
			}

			form {
				.form-control {
					margin-bottom: 10px;
				}
			}
		}
	}
}

.home-katalog-list {
	&.katalog-list-1 {
		position: relative;
		border: 1px solid $hr-border-color;
		padding: 35px;
		height: 280px;
		overflow: hidden;

		&:after {
			content: '';
			position: absolute;
			top: 40px;
			right: -20px;
			background: url(#{$img-path}katalog-bg.png);
			width: 360px;
			height: 319px;
			z-index: 0;
		}

		.catalog-title {
			padding: 10px 0;

			span {
				display: block;
				font-size: 16px;
				font-weight: 500;
				color: #000;
				margin-bottom: 10px;
			}

			a {
				font-size: 13px;
				font-weight: 500;
				text-decoration: underline;
				display: block;
			}
		}

		.catalog-icon {
			position: absolute;
			bottom: 45px;
			left: 35px;

			i {
				display: block;
			}
		}
	}
}

.home-hizmetler-list {
	&.hizmetler-list-1 {
		.item {
			margin-bottom: 40px;

			.text-box {
				@include textBox();

				.btn-more {
					display: inline-block;
					width: 30px;
					height: 30px;
					border-radius: 30px;
					border: 2px solid darken($hr-border-color, 10%);
					text-align: center;
					color: darken($hr-border-color, 10%);
					margin-top: 20px;

					i {
						font-size: 20px;
						line-height: 26px;
					}
				}
			}
		}
	}

	&.hizmetler-list-2 {
		.item {
			position: relative;
			background: $dark;

			.image-box {
				opacity: 0.4;
			}

			.text-box {
				position: absolute;
				bottom: 0;
				left: 0;
				padding: 20px;

				.item-title, .item-text {
					color: #fff;
				}
			}

			.btn-more {
				width: 40px;
				height: 50px;
				position: absolute;
				top: 20px;
				right: 20px;
				border: 1px solid #fff;
				color: #fff;
				text-align: center;

				i {
					line-height: 48px;
					font-size: 20px;
					@include transition(all ease 0.3s);
				}

				&:hover {
					i {
						@include transform(rotate(360deg));
					}
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.item {
				.image-box {
					img {
						width: 100%;
					}
				}
			}
		}
	}
}

.home-video-galeri-list {
	.item {
		position: relative;
		border: 1px solid $hr-border-color;

		> a.blank-page {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		.image-box {
			position: relative;

			.hover {
				.hover-bg {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0.5;
					background: #000;
				}

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					font-size: 44px;
					line-height: 50px;
					width: 50px;
					text-align: center;
					margin-top: -25px;
					margin-left: -25px;
				}
			}
		}

		.text-box {
			padding: 0 20px;

			.item-title {
				display: table-cell;
				vertical-align: middle;
				height: 73px;
			}
		}
	}
}

.home-fotograf-galeri-list {
	.item {
		margin-bottom: 20px;
		position: relative;

		.blank-page {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.zoom-box {
			position: absolute;
			top: 0;
			right: 0;
			padding: 15px;
			background: #fff;
			border: 1px solid $input-border-color;
			z-index: 1;

			i {
				display: block;
			}
		}

		.image-box {
			border: 1px solid $hr-border-color;

			img {
			}
		}

		.text-box {
			@include textBox();
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 20px;
			@include gradient-y(transparent, #000);

			.item-title {
				margin: 0 0 5px 0;
				color: #fff;
			}

			.item-text {
				color: #fff;
				margin: 0;
			}
		}
	}
}

.home-iletisim-list {
	position: relative;
	z-index: 1;
	color: #fff;

	.or-box {
		span {
			display: none;
			width: 40px;
			height: 40px;
			border-radius: 40px;
			background: fade-out(#fff, 0.8);
			font-size: 12px;
			font-weight: 400;
			line-height: 40px;
			text-transform: capitalize;
			color: #fff;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			width: 1px;
			height: 100%;
			background: fade-out(#fff, 0.8);
		}
	}

	.label-text {
		display: table-cell;
		vertical-align: middle;
		height: 80px;
		padding: 0 20px;

		> i {
			display: block;
			float: left;
			margin-right: 20px;
			font-size: 28px;
		}

		span.text {
			font-size: 14px;
			font-weight: 400;
			display: block;
			float: left;
			text-transform: capitalize;
			padding: 10px 0;
			max-width: 150px;
		}

		span.arrow {
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -10px;

			i {
				font-size: 18px;
				display: block;
			}
		}
	}

	.label-value {
		display: table-cell;
		vertical-align: middle;
		height: 80px;
		padding: 0 20px;
		width: 1%;
		text-align: center;

		a {
			font-size: 16px;
			font-weight: 700;
			color: #fff;
		}
	}
}